.login-page {
    background: url(../images/bg-backcards.jpg);
    background-size: cover;
}

.login-form.login-signin {
    padding: 20px;
    color: #3c3c33;
    border-radius: 16px;
    border: solid 1px #ccc;
    background-color: #fff;
}

@media (min-width: 992px) {
    .login-page .mb-lg-20, .my-lg-20 {
        margin-bottom: 2rem !important;
    }
}

.dbName{
    color: green !important;
    font-weight: bold !important;
    margin-left: 10px;
}

.btn-position{
    float: right;
}

.btn.btn-primary.disabled, .btn.btn-primary:disabled {
    color: #FFFFFF;
    background-color: #3699FF;
    border-color: #3699FF;
    cursor: not-allowed;
}

.csvBoxHeight{
    height: 150px !important;
}

.lable-error-color{
    color: #F64E60 !important;
    font-size: 13px;
    font-weight: 400;
}

.lable-success-color{
    color: green !important;
    font-size: 13px;
    font-weight: 400;
}

.hide-controls{
    display: none;
}